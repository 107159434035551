// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../../../core/Icon.bs.js";
import * as Link$Ucidata from "../../../core/router/Link.bs.js";
import * as Table$Ucidata from "../../../core/Table.bs.js";
import * as Button$Ucidata from "../../../core/Button.bs.js";
import * as IfUciRole$Ucidata from "../../../core/conditionals/IfUciRole.bs.js";
import * as OptionMap$Ucidata from "../../../core/conditionals/OptionMap.bs.js";
import * as PopupMenu$Ucidata from "../../../core/PopupMenu.bs.js";
import * as Text_Span$Ucidata from "../../../core/text/Text_Span.bs.js";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as ProDuotoneSvgIcons from "@fortawesome/pro-duotone-svg-icons";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";

var Table = Table$Ucidata.Make({});

function make(codes, uci, user, events) {
  var extraColumns = user !== undefined && uci !== undefined ? IfUciRole$Ucidata.check(undefined, user, uci, [/* BackOffice */2]) : false;
  var sectionStatusColumns = Belt_Array.map(codes.codesSection, (function (section) {
          return {
                  label: section.name,
                  extract: (function (item) {
                      return Belt_Option.mapWithDefault(Belt_Array.getBy(item.sections_status, (function (s) {
                                        return s.code_section_id === section.id;
                                      })), "", (function (sectionStatus) {
                                    return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesDocStatus, (function (elt) {
                                                      return elt.id === sectionStatus.code_doc_status_id;
                                                    })), "", (function (elt) {
                                                  return elt.name;
                                                }));
                                  }));
                    }),
                  options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                };
        }));
  return Curry._1(Table.Column.make, [].concat([
                  {
                    label: "Name",
                    extract: (function (item) {
                        return item.name;
                      }),
                    options: Curry._7(Table.Column.Options.make, true, true, (function (label, param) {
                            return React.createElement(Text_Span$Ucidata.make, {
                                        className: "mx-2",
                                        children: label
                                      });
                          }), undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Status",
                    extract: (function (item) {
                        return Belt_Option.mapWithDefault(item.status_id, "0", (function (prim) {
                                      return String(prim);
                                    }));
                      }),
                    options: Curry._7(Table.Column.Options.make, true, true, (function (value, param) {
                            var id = Belt_Option.getExn(Belt_Int.fromString(value));
                            var title = Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesStatus, (function (status) {
                                        return status.id === id;
                                      })), "", (function (status) {
                                    return status.name;
                                  }));
                            var markup;
                            switch (id) {
                              case 1 :
                                  markup = [
                                    "bg-green-400",
                                    React.createElement(Icon$Ucidata.make, {
                                          icon: ProDuotoneSvgIcons.faCheckDouble
                                        })
                                  ];
                                  break;
                              case 2 :
                                  markup = [
                                    "bg-yellow-400",
                                    React.createElement(Icon$Ucidata.make, {
                                          icon: ProSolidSvgIcons.faQuestion
                                        })
                                  ];
                                  break;
                              case 3 :
                                  markup = [
                                    "bg-red-400",
                                    React.createElement(Icon$Ucidata.make, {
                                          icon: ProSolidSvgIcons.faExclamation
                                        })
                                  ];
                                  break;
                              default:
                                markup = undefined;
                            }
                            return React.createElement(OptionMap$Ucidata.make, {
                                        option: markup,
                                        render: (function (param) {
                                            return React.createElement("div", {
                                                        className: param[0] + " w-6 h-6 inline-flex items-center justify-center rounded",
                                                        title: title
                                                      }, param[1]);
                                          })
                                      });
                          }), undefined, "text-white", (function (param) {
                            return !extraColumns;
                          }), undefined)
                  },
                  {
                    label: "Hold. Str.",
                    extract: (function (item) {
                        return Belt_Option.getWithDefault(item.holding_structure, "N/A");
                      }),
                    options: Curry._7(Table.Column.Options.make, true, true, (function (label, item) {
                            return React.createElement(Link$Ucidata.make, {
                                        href: "/assets/compartiment/" + String(item.uci_id) + "/" + String(Belt_Option.getWithDefault(item.uci_item_group_id, item.id)) + "/holding",
                                        children: React.createElement(Text_Span$Ucidata.make, {
                                              className: "mx-2",
                                              children: label
                                            })
                                      });
                          }), undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "",
                    extract: (function (param) {
                        return "";
                      }),
                    options: Curry._7(Table.Column.Options.make, true, undefined, (function (param, item) {
                            return React.createElement(PopupMenu$Ucidata.make, {
                                        render: (function (setShowMenu) {
                                            var tmp = {
                                              roles: [
                                                /* Manager */0,
                                                /* BackOffice */2
                                              ],
                                              bypassWhenUserRoles: [
                                                /* AccountAdmin */1,
                                                /* HeadBackOffice */2
                                              ],
                                              children: React.createElement(PopupMenu$Ucidata.MenuElement.make, {
                                                    onClick: (function (param) {
                                                        Curry._1(events.onEditItemClick, item);
                                                        return Curry._1(setShowMenu, (function (param) {
                                                                      return false;
                                                                    }));
                                                      }),
                                                    children: React.createElement(Icon$Ucidata.make, {
                                                          text: "Edit",
                                                          icon: ProRegularSvgIcons.faEdit,
                                                          fixedWidth: true
                                                        }),
                                                    key: "edit-item"
                                                  })
                                            };
                                            if (uci !== undefined) {
                                              tmp.uci = Caml_option.valFromOption(uci);
                                            }
                                            return React.createElement(React.Fragment, undefined, React.createElement(PopupMenu$Ucidata.MenuElement.make, {
                                                            onClick: (function (param) {
                                                                Curry._1(events.onDocumentsClick, item);
                                                                return Curry._1(setShowMenu, (function (param) {
                                                                              return false;
                                                                            }));
                                                              }),
                                                            children: React.createElement(Icon$Ucidata.make, {
                                                                  text: "Documents",
                                                                  icon: ProRegularSvgIcons.faFileAlt,
                                                                  fixedWidth: true
                                                                }),
                                                            key: "show-documents"
                                                          }), React.createElement(IfUciRole$Ucidata.make, tmp), React.createElement(PopupMenu$Ucidata.MenuElement.make, {
                                                            onClick: (function (param) {
                                                                Curry._1(events.onShowPosition, item);
                                                                return Curry._1(setShowMenu, (function (param) {
                                                                              return false;
                                                                            }));
                                                              }),
                                                            children: React.createElement(Icon$Ucidata.make, {
                                                                  text: "Show Position",
                                                                  icon: ProRegularSvgIcons.faBalanceScale,
                                                                  fixedWidth: true
                                                                }),
                                                            key: "show-position"
                                                          }), React.createElement(PopupMenu$Ucidata.MenuElement.make, {
                                                            onClick: (function (param) {
                                                                Curry._1(events.onShowHistory, item);
                                                                return Curry._1(setShowMenu, (function (param) {
                                                                              return false;
                                                                            }));
                                                              }),
                                                            children: React.createElement(Icon$Ucidata.make, {
                                                                  text: "Show History",
                                                                  icon: ProRegularSvgIcons.faHistory,
                                                                  fixedWidth: true
                                                                }),
                                                            key: "show-history"
                                                          }));
                                          }),
                                        children: React.createElement(Button$Ucidata.make, {
                                              style: /* Unstyled */4,
                                              className: "h-8 px-2",
                                              children: React.createElement(Icon$Ucidata.make, {
                                                    icon: ProRegularSvgIcons.faEllipsisH,
                                                    className: "text-base"
                                                  })
                                            })
                                      });
                          }), undefined, "", undefined, undefined)
                  },
                  {
                    label: "Cadaster Nb.",
                    extract: (function (item) {
                        return Belt_Option.getWithDefault(item.cadaster_nb, "N/A");
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Internal Ref.",
                    extract: (function (item) {
                        return item.internal_ref;
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Transaction",
                    extract: (function (item) {
                        return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesEvent, (function (elt) {
                                          return elt.id === item.code_event_id;
                                        })), "", (function (elt) {
                                      return elt.name;
                                    }));
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Tr. Type",
                    extract: (function (item) {
                        return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesType, (function (elt) {
                                          return elt.id === item.code_type_id;
                                        })), "", (function (elt) {
                                      return elt.name;
                                    }));
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Tr. Category",
                    extract: (function (item) {
                        return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesSubtype, (function (elt) {
                                          return elt.id === item.code_subtype_id;
                                        })), "", (function (elt) {
                                      return elt.name;
                                    }));
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Date",
                    extract: (function (item) {
                        return new Date(item.created_at).toUTCString();
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Asset Type",
                    extract: (function (item) {
                        return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesAssetType, (function (elt) {
                                          return elt.id === item.code_asset_type_id;
                                        })), "", (function (elt) {
                                      return elt.name;
                                    }));
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Units",
                    extract: (function (item) {
                        return item.units.toLocaleString();
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Denom.",
                    extract: (function (item) {
                        return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesDenom, (function (elt) {
                                          return elt.id === item.code_denom_id;
                                        })), "", (function (elt) {
                                      return elt.name;
                                    }));
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Cash",
                    extract: (function (item) {
                        return item.price.toLocaleString();
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Curr.",
                    extract: (function (item) {
                        return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesCcy, (function (elt) {
                                          return elt.id === item.code_ccy_id;
                                        })), "", (function (elt) {
                                      return elt.name;
                                    }));
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Transm. Id",
                    extract: (function (item) {
                        return String(item.transm_id);
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Valid. Id",
                    extract: (function (item) {
                        return Belt_Option.mapWithDefault(item.valid_id, "", (function (valid_id) {
                                      return String(valid_id);
                                    }));
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  }
                ], sectionStatusColumns, [{
                    label: "Last Review",
                    extract: (function (item) {
                        return new Date(item.last_review_date).toUTCString();
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, (function (param) {
                            return !extraColumns;
                          }), undefined)
                  }]));
}

var Column;

export {
  Table ,
  Column ,
  make ,
  
}
/* Table Not a pure module */
