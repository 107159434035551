// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../../../core/Icon.bs.js";
import * as Text$Ucidata from "../../../core/text/Text.bs.js";
import * as Codes$Ucidata from "../../../Codes.bs.js";
import * as Hooks$Ucidata from "../../../Hooks.bs.js";
import * as Button$Ucidata from "../../../core/Button.bs.js";
import * as Client$Ucidata from "../../../../Client.bs.js";
import * as Layout$Ucidata from "../../../core/Layout.bs.js";
import * as Loader$Ucidata from "../../../core/loader/Loader.bs.js";
import * as Wretch$Ucidata from "../../../../libs/Wretch.bs.js";
import * as Section$Ucidata from "../../../core/Section.bs.js";
import * as UciItem$Ucidata from "../../../../models/UciItem.bs.js";
import * as Font_Div$Ucidata from "../../../core/font/Font_Div.bs.js";
import * as Position$Ucidata from "../../../../models/Position.bs.js";
import * as IfUciRole$Ucidata from "../../../core/conditionals/IfUciRole.bs.js";
import * as OptionMap$Ucidata from "../../../core/conditionals/OptionMap.bs.js";
import * as Text_Span$Ucidata from "../../../core/text/Text_Span.bs.js";
import * as Validation$Ucidata from "../../../core/validation/Validation.bs.js";
import * as Collapsible$Ucidata from "../../../core/Collapsible.bs.js";
import * as Input_Number$Ucidata from "../../../core/input/Input_Number.bs.js";
import * as Input_Select$Ucidata from "../../../core/input/Input_Select.bs.js";
import * as Webapi__Dom__Document from "bs-webapi/src/Webapi/Dom/Webapi__Dom__Document.bs.js";
import * as Validation_Rules$Ucidata from "../../../core/validation/Validation_Rules.bs.js";
import * as Validation_Context$Ucidata from "../../../core/validation/Validation_Context.bs.js";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";

function CompartimentTransactions_ItemPosition(Props) {
  var itemId = Props.itemId;
  var onClose = Props.onClose;
  var onOperation = Props.onOperation;
  var uci = Props.uci;
  var client = Client$Ucidata.useClient(undefined);
  var match = Hooks$Ucidata.useFixedReference(undefined);
  var refetchPosition = match[1];
  var match$1 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, [match[0]], undefined, "/uci_item/" + itemId + "/position");
  var position = Client$Ucidata.data(true, match$1[0]);
  var codes = Codes$Ucidata.useCodes(undefined);
  var match$2 = React.useState(function () {
        
      });
  var setOperationType = match$2[1];
  var operationType = match$2[0];
  var match$3 = React.useState(function () {
        return 1;
      });
  var setOperationAmount = match$3[1];
  var operationAmount = match$3[0];
  var match$4 = React.useState(function () {
        return 1;
      });
  var setOperationPrice = match$4[1];
  var operationPrice = match$4[0];
  var match$5 = Client$Ucidata.useRequest(undefined, undefined, (function (param) {
          return Belt_Option.mapWithDefault(position, Promise.resolve(undefined), (function (position) {
                        var codeEvent = Belt_Option.getExn(operationType).id;
                        var init = Position$Ucidata.toUciItem(Position$Ucidata.Detailed.toPos(position), codeEvent);
                        return Wretch$Ucidata.$$then(UciItem$Ucidata.create(client, {
                                        id: init.id,
                                        uci_item_group_id: init.uci_item_group_id,
                                        uci_id: init.uci_id,
                                        name: init.name,
                                        cadaster_nb: init.cadaster_nb,
                                        internal_ref: init.internal_ref,
                                        units: operationAmount,
                                        price: operationPrice,
                                        code_type_id: init.code_type_id,
                                        code_event_id: init.code_event_id,
                                        code_subtype_id: init.code_subtype_id,
                                        code_asset_type_id: init.code_asset_type_id,
                                        code_denom_id: init.code_denom_id,
                                        code_ccy_id: init.code_ccy_id,
                                        code_uci_item_type_id: init.code_uci_item_type_id,
                                        status_id: init.status_id,
                                        holding_structure: init.holding_structure,
                                        status_hs: init.status_hs
                                      }), (function (param) {
                                      Curry._1(refetchPosition, undefined);
                                      return Belt_Option.forEach(onOperation, (function (onOperation) {
                                                    return Curry._1(onOperation, undefined);
                                                  }));
                                    }));
                      }));
        }));
  var performOperation = match$5[1];
  var operationState = match$5[0];
  var selling = Belt_Option.mapWithDefault(operationType, false, (function (param) {
          return param.is_sell;
        }));
  var committing = Belt_Option.mapWithDefault(operationType, false, (function (param) {
          return param.code_uci_item_type_id === 2;
        }));
  var render = function (codes, position) {
    var currency = Belt_Array.getBy(codes.codesCcy, (function (elt) {
            return elt.id === position.code_ccy_id;
          }));
    var tmp = {
      name: "code-type",
      options: codes.codesType,
      onChange: (function (param) {
          
        }),
      label: React.createElement(Text$Ucidata.make, {
            children: "Transaction Type"
          }),
      isDisabled: true,
      getOptionLabel: (function (codeType) {
          return codeType.name;
        }),
      getOptionValue: (function (codeType) {
          return codeType.id;
        })
    };
    var tmp$1 = Belt_Array.getBy(codes.codesType, (function (elt) {
            return elt.id === position.code_type_id;
          }));
    if (tmp$1 !== undefined) {
      tmp.value = Caml_option.valFromOption(tmp$1);
    }
    var tmp$2 = {
      name: "code-subtype",
      options: codes.codesSubtype,
      onChange: (function (param) {
          
        }),
      label: React.createElement(Text$Ucidata.make, {
            children: "Transaction Category"
          }),
      isDisabled: true,
      getOptionLabel: (function (codeSubType) {
          return codeSubType.name;
        }),
      getOptionValue: (function (codeSubType) {
          return codeSubType.id;
        })
    };
    var tmp$3 = Belt_Array.getBy(codes.codesSubtype, (function (elt) {
            return elt.id === position.code_subtype_id;
          }));
    if (tmp$3 !== undefined) {
      tmp$2.value = Caml_option.valFromOption(tmp$3);
    }
    var tmp$4 = {
      name: "asset-type",
      options: codes.codesAssetType,
      onChange: (function (param) {
          
        }),
      label: React.createElement(Text$Ucidata.make, {
            children: "Asset Type"
          }),
      isDisabled: true,
      getOptionLabel: (function (assetType) {
          return assetType.name;
        }),
      getOptionValue: (function (assetType) {
          return assetType.id;
        })
    };
    var tmp$5 = Belt_Array.getBy(codes.codesAssetType, (function (elt) {
            return elt.id === position.code_asset_type_id;
          }));
    if (tmp$5 !== undefined) {
      tmp$4.value = Caml_option.valFromOption(tmp$5);
    }
    var tmp$6 = {
      name: "code-denom",
      options: codes.codesDenom,
      onChange: (function (param) {
          
        }),
      label: React.createElement(Text$Ucidata.make, {
            children: "Denomination"
          }),
      isDisabled: true,
      getOptionLabel: (function (denom) {
          return denom.name;
        }),
      getOptionValue: (function (denom) {
          return denom.id;
        })
    };
    var tmp$7 = Belt_Array.getBy(codes.codesDenom, (function (elt) {
            return elt.id === position.code_denom_id;
          }));
    if (tmp$7 !== undefined) {
      tmp$6.value = Caml_option.valFromOption(tmp$7);
    }
    var tmp$8 = {
      role: /* Manager */0,
      children: React.createElement(Section$Ucidata.make, {
            children: React.createElement(Collapsible$Ucidata.make, {
                  openedClassName: "mb-8",
                  children: React.createElement(Validation$Ucidata.make, {
                        render: (function (validation) {
                            var tmp = {
                              name: "operation-type",
                              options: Belt_Array.keep(codes.codesEvent, (function (elt) {
                                      return elt.name !== "Capital Call A";
                                    })),
                              onChange: (function (value) {
                                  return Curry._1(setOperationType, (function (param) {
                                                return value;
                                              }));
                                }),
                              label: React.createElement(Text$Ucidata.make, {
                                    children: "Operation Type"
                                  }),
                              validate: Validation_Rules$Ucidata.$$Option.required(undefined, undefined),
                              getOptionLabel: (function (codeEvent) {
                                  return codeEvent.name;
                                }),
                              getOptionValue: (function (codeEvent) {
                                  return codeEvent.id;
                                })
                            };
                            var tmp$1 = Belt_Array.getBy(codes.codesEvent, (function (elt) {
                                    return elt.id === Belt_Option.mapWithDefault(operationType, 0, (function (t) {
                                                  return t.id;
                                                }));
                                  }));
                            if (tmp$1 !== undefined) {
                              tmp.value = Caml_option.valFromOption(tmp$1);
                            }
                            var tmp$2 = Belt_Option.getExn(Belt_Option.map(Webapi__Dom__Document.asHtmlDocument(document), (function ($$document) {
                                        return Caml_option.nullable_to_opt($$document.body);
                                      })));
                            if (tmp$2 !== undefined) {
                              tmp.menuPortalTarget = Caml_option.valFromOption(tmp$2);
                            }
                            var units = committing ? position.opn_comm_units : position.units;
                            var partial_arg = selling ? [
                                Validation_Rules$Ucidata.Int.min(undefined, 1),
                                Validation_Rules$Ucidata.Int.max(undefined, units)
                              ] : [Validation_Rules$Ucidata.Int.min(undefined, 1)];
                            var tmp$3 = {
                              id: "amount",
                              value: operationAmount,
                              label: React.createElement("div", {
                                    className: "inline-flex space-x-2"
                                  }, React.createElement(Text_Span$Ucidata.make, {
                                        children: committing ? "Amount" : "Units"
                                      }), React.createElement(Font_Div$Ucidata.make, {
                                        style: /* Italic */4,
                                        children: React.createElement(Text$Ucidata.make, {
                                              children: "(" + (
                                                committing ? "committed" : "available"
                                              ) + ": " + String(units) + ")"
                                            })
                                      })),
                              validate: (function (param) {
                                  return Validation_Rules$Ucidata.compose(partial_arg, param);
                                }),
                              onChange: (function (value) {
                                  return Curry._1(setOperationAmount, (function (param) {
                                                return value;
                                              }));
                                }),
                              placeholder: "Amount",
                              min: "1",
                              max: selling ? String(units) : ""
                            };
                            if (operationType !== undefined) {
                              tmp$3.validateDependency = Caml_option.valFromOption(operationType);
                            }
                            return React.createElement("div", {
                                        className: "space-y-4"
                                      }, React.createElement("div", undefined, React.createElement(Input_Select$Ucidata.make, tmp)), React.createElement("div", undefined, React.createElement(Input_Number$Ucidata.make, tmp$3)), React.createElement("div", undefined, React.createElement(Input_Number$Ucidata.make, {
                                                id: "price",
                                                value: operationPrice,
                                                label: React.createElement("div", {
                                                      className: "inline-flex space-x-2"
                                                    }, React.createElement(Text_Span$Ucidata.make, {
                                                          children: "Price"
                                                        }), React.createElement(Font_Div$Ucidata.make, {
                                                          style: /* Italic */4,
                                                          children: React.createElement(Text$Ucidata.make, {
                                                                children: Belt_Option.mapWithDefault(currency, "", (function (param) {
                                                                        return "(" + param.name + ")";
                                                                      }))
                                                              })
                                                        })),
                                                validate: Validation_Rules$Ucidata.Int.min("Minimum price is 1.", 1),
                                                onChange: (function (value) {
                                                    return Curry._1(setOperationPrice, (function (param) {
                                                                  return value;
                                                                }));
                                                  }),
                                                placeholder: "Price",
                                                min: "1"
                                              })), React.createElement("div", undefined, React.createElement(Button$Ucidata.make, {
                                                style: /* Primary */0,
                                                disabled: Validation_Context$Ucidata.invalid(validation) || operationState === /* Pending */1,
                                                spinner: operationState === /* Pending */1,
                                                onClick: (function (param) {
                                                    Curry._1(performOperation, undefined);
                                                    
                                                  }),
                                                children: React.createElement(Text$Ucidata.make, {
                                                      children: "Perform Operation"
                                                    })
                                              })));
                          })
                      }),
                  render: (function (param) {
                      return React.createElement(Font_Div$Ucidata.make, {
                                  style: /* Subtitle */2,
                                  children: React.createElement(Text$Ucidata.make, {
                                        children: "Operation"
                                      })
                                });
                    })
                })
          })
    };
    if (uci !== undefined) {
      tmp$8.uci = Caml_option.valFromOption(uci);
    }
    return React.createElement(React.Fragment, undefined, React.createElement(Section$Ucidata.make, {
                    unstyled: true,
                    children: React.createElement(Layout$Ucidata.make, {
                          style: /* FlexBetween */3,
                          children: null
                        }, React.createElement(Font_Div$Ucidata.make, {
                              style: /* Title */1,
                              children: React.createElement(Icon$Ucidata.make, {
                                    text: position.name,
                                    icon: ProSolidSvgIcons.faBalanceScale,
                                    className: "mr-2"
                                  })
                            }), React.createElement("div", {
                              className: "flex items-center justify-center space-x-4"
                            }, React.createElement(OptionMap$Ucidata.make, {
                                  option: onClose,
                                  render: (function (onClose) {
                                      return React.createElement(Button$Ucidata.make, {
                                                  onClick: (function (param) {
                                                      return Curry._1(onClose, undefined);
                                                    }),
                                                  children: React.createElement(Text$Ucidata.make, {
                                                        children: "Close"
                                                      })
                                                });
                                    })
                                })))
                  }), React.createElement(Section$Ucidata.make, {
                    children: React.createElement("div", {
                          className: "space-y-4"
                        }, React.createElement(Layout$Ucidata.make, {
                              style: /* Grid2Cols */0,
                              children: null
                            }, React.createElement(Input_Select$Ucidata.make, tmp), React.createElement(Input_Select$Ucidata.make, tmp$2), React.createElement(Input_Select$Ucidata.make, tmp$4)), React.createElement(Layout$Ucidata.make, {
                              style: /* Grid2Cols */0,
                              children: null
                            }, React.createElement(Input_Number$Ucidata.make, {
                                  id: "units",
                                  value: position.units,
                                  label: React.createElement(Text$Ucidata.make, {
                                        children: "Units"
                                      }),
                                  disabled: true
                                }), React.createElement(Input_Select$Ucidata.make, tmp$6), React.createElement(Input_Number$Ucidata.make, {
                                  id: "initial-comm-units",
                                  value: position.initial_comm,
                                  label: React.createElement(Text$Ucidata.make, {
                                        children: "Initial Commitment"
                                      }),
                                  disabled: true
                                }), React.createElement(Input_Number$Ucidata.make, {
                                  id: "open-comm-units",
                                  value: position.opn_comm_units,
                                  label: React.createElement(Text$Ucidata.make, {
                                        children: "Open Committment"
                                      }),
                                  disabled: true
                                })))
                  }), React.createElement(IfUciRole$Ucidata.make, tmp$8));
  };
  return React.createElement("div", {
              className: "space-y-8"
            }, React.createElement(Loader$Ucidata.make, {
                  className: "text-6xl my-10",
                  children: codes !== undefined && position !== undefined ? Caml_option.some(render(codes, position)) : undefined
                }));
}

var make = React.memo(CompartimentTransactions_ItemPosition);

export {
  make ,
  
}
/* make Not a pure module */
