// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function empty(commitmentOpt, uciId) {
  var commitment = commitmentOpt !== undefined ? commitmentOpt : false;
  return {
          id: undefined,
          uci_item_group_id: undefined,
          uci_id: uciId,
          name: "",
          cadaster_nb: undefined,
          internal_ref: "",
          units: 0,
          price: 0,
          code_type_id: 0,
          code_event_id: 0,
          code_subtype_id: 0,
          code_asset_type_id: 0,
          code_denom_id: 0,
          code_ccy_id: 0,
          code_uci_item_type_id: commitment ? 2 : 1,
          status_id: undefined,
          holding_structure: undefined,
          status_hs: undefined
        };
}

var SectionStatus = {};

var UciGroup = {};

var Uci = {};

function toItem(detailed) {
  return {
          id: detailed.id,
          uci_item_group_id: detailed.uci_item_group_id,
          uci_id: detailed.uci_id,
          name: detailed.name,
          cadaster_nb: detailed.cadaster_nb,
          internal_ref: detailed.internal_ref,
          units: detailed.units,
          price: detailed.price,
          code_type_id: detailed.code_type_id,
          code_event_id: detailed.code_event_id,
          code_subtype_id: detailed.code_subtype_id,
          code_asset_type_id: detailed.code_asset_type_id,
          code_denom_id: detailed.code_denom_id,
          code_ccy_id: detailed.code_ccy_id,
          code_uci_item_type_id: detailed.code_uci_item_type_id,
          status_id: detailed.status_id,
          holding_structure: detailed.holding_structure,
          status_hs: detailed.status_hs
        };
}

var Detailed = {
  SectionStatus: SectionStatus,
  UciGroup: UciGroup,
  Uci: Uci,
  toItem: toItem
};

function $$fetch(client, id) {
  return client.url("/uci_item/" + id).get().json();
}

function create(client, item) {
  return client.url("/uci_item").post(item).json();
}

function update(client, item) {
  return client.url("/uci_item/" + String(Belt_Option.getExn(item.id))).put(item).json();
}

function save(client, item) {
  var match = item.id;
  if (match !== undefined) {
    return update(client, item);
  } else {
    return create(client, item);
  }
}

function updateStatus(client, id, newStatus) {
  return client.url("/uci_item/" + id + "/update_status").post({
                sections_status: [newStatus]
              }).json();
}

function updateStructStatus(client, id, newStatus) {
  return client.url("/uci_item/" + id + "/update_struct_status").post({
                status_hs: newStatus
              }).json();
}

export {
  empty ,
  Detailed ,
  $$fetch ,
  create ,
  update ,
  save ,
  updateStatus ,
  updateStructStatus ,
  
}
/* No side effect */
